import { setContext }   from "@apollo/client/link/context";
import { LocalStorage } from "../../../constants/localStorage";
import { ApolloLink }   from "@apollo/client/link/core";

export const authLink: ApolloLink = setContext((operation, { headers }) => {
  const token: string | null = localStorage.getItem(LocalStorage.AuthToken);
  return {
    headers: {
      ...headers,
      Authorization: token ? token : "",
    }
  }
});

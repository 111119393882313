import React from "react";
import cx    from "classnames";
import "./ProgressChunk.scss";


export interface IProgressChunk {
  progress: number,
  className?: string,
  color: string
}


const ProgressChunk: React.FC<IProgressChunk> = ({ progress, className, color }) => {

  const classes: string = cx(
    "progress-chunk",
    className,
  );
  const whiteWidth = (100 - progress);
  const colorWidth = (100 - progress);
  const background = "linear-gradient(to left, #e9eeef " + whiteWidth + "%, "+color+" "+colorWidth+"%)";
  return (
    <div style={{background:background}} className={classes}/>
  );
};

export default ProgressChunk;

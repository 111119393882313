import { createTheme } from '@mui/material/styles';

let customTheme = createTheme({
  palette: {
    primary: {
      main: '#9229FF',
    },
    secondary: {
      main: '#C8D6D4',
    },
    warning: {
      main : '#FF7D00'
    },
    info: {
      main: '#00BBCE',
    },
    error: {
      main: '#FF404C',
    },
  },
  typography: {
      body1: {
        fontSize: "1.5vh"
      },
    fontFamily: 'Comfortaa, Arial',
    fontSize : 18
  },
});

export default customTheme
import React from "react";
import cx    from "classnames";
import "./History.scss";
import { useTranslation } from 'react-i18next';
import DOMPurify           from "dompurify";


interface IHistory {
  className?: string,
}


/**
 * History Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IHistory>}
 */
const History: React.FC<IHistory> = ({ className }) => {

  const classes: string = cx(
    "history",
    className,
  );
  const { t, i18n } = useTranslation();

  const history = [
    {
      title:t('views.history.startTitle'),
      content:t('views.history.startContent')
    },
    {
      title:t('views.history.chatbotTitle'),
      content:t('views.history.chatbotContent')
    },
    {
      title:t('views.history.cognitiveTitle'),
      content:t('views.history.cognitiveContent')
    },
    {
      title:t('views.history.foundationsTitle'),
      content:t('views.history.foundationsContent')
    },
    {
      title:t('views.history.metacognitionTitle'),
      content:t('views.history.metacognitionContent')
    },
    {
      title:t('views.history.pixTitle'),
      content:t('views.history.pixContent')
    }
  ]

  function _buildStory(){
    return (
      history.map((key, index) => {
        return (
          <div>
            <h5 dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(key.title),
            }}></h5>
            <p dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(key.content),
            }}></p>
          </div>
        )
      })
    );
  }

  return (
    <div className={classes}>
      <h3>{t('views.history.title')}</h3>

      {_buildStory()}
    </div>
  );
};

export default History;

/**
 * Check file size
 * @param file {File}
 * @param max {number} In Kilo octet
 */
export function checkFileSize(file: File, max: number): boolean {
  const octet: number = 1024;
  const size: number  = Math.round(file.size / octet);

  return size <= max;
}

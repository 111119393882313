import { gql } from "@apollo/client";

const FEED_MATRIX_PROGRESSION_QUERY = gql`
    query {
        progress {
            matrix {
                id
                name
            }
        }
    }
`;

const FEED_TODAY_CONTENT_QUERY = gql`
    query {
        progress {
            todayValid
            todayInvalid
        }
    }
`;

const FEED_SKILLS_PROGRESSION_QUERY = gql`
    query {
        progress {
            skills {
                skill
                level
                goal
                progress
                minLevel
                nbAcquis
                validAcquis
            }
        }
    }
`;

const FEED_SCORE_PROGRESSION_QUERY = gql`
    query {
        progress {
            score {
                userScore
                goalScore
            }
        }
    }
`;

const FEED_DOMAINS_PROGRESSION_QUERY = gql`
    query {
        progress {
            domains {
                title
                level
                goal
            }
        }
    }
`;


export {
  FEED_TODAY_CONTENT_QUERY,
  FEED_MATRIX_PROGRESSION_QUERY,
  FEED_SKILLS_PROGRESSION_QUERY,
  FEED_SCORE_PROGRESSION_QUERY,
  FEED_DOMAINS_PROGRESSION_QUERY,
};

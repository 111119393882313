import React                   from "react";
import BannerWrapper           from "../Containers/BannerWrapper/BannerWrapper";
import { RouteComponentProps } from "react-router";


interface IAuthenticatedRoute {
  component: React.ComponentType<RouteComponentProps & any>
  path: string,
  routeProps: RouteComponentProps<any>,
}


/**
 * AuthenticatedRoute Functional Component
 * @constructor
 * @return {React.FC<IAuthenticatedRoute>}
 */
const AuthenticatedRoute: React.FC<IAuthenticatedRoute> = ({ component: Component, path, routeProps }) => {
  return (
    <BannerWrapper path={path}>
      <Component {...routeProps} authed={true}/>
    </BannerWrapper>
  );
};

export default AuthenticatedRoute;

import React, { useState } from "react";
import cx                  from "classnames";
import DOMPurify           from "dompurify";
import "./Accordion.scss";


export interface IKey {
  id: string | number,
  title: string,
  value: string,
}


interface IAccordion {
  className?: string,
  keys: Array<IKey>
}


/**
 * Accordion Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param keys
 * @constructor
 * @return {React.FC<ISkills>}
 */
const Accordion: React.FC<IAccordion> = ({ className, keys }) => {

  const [active, setActive] = useState<string | number | null | undefined>(null);

  const classes: string = cx(
    "accordion",
    className,
  );


  /**
   * Handle button click
   */
  function _handleClick(event: any): void {
    const { id } = event?.target?.dataset;
    if (id === active) setActive(null);
    else setActive(event?.target?.dataset?.id);
  }


  /**
   * Accordion builder
   */
  function _buildAccordion(): Array<JSX.Element> {
    return (
      keys.map((key, index) => {
        const isActive: boolean = active === key.id;
        return (
          <React.Fragment key={index}>
            <button
              data-id={key.id}
              className={cx("accordion__key", { "accordion__key--active": isActive })}
              onClick={e => { _handleClick(e) }}
            >
              {key.title}
            </button>
            <div
              className={cx("accordion__panel", { "accordion__panel--active": isActive })}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(key.value),
              }}
            />
          </React.Fragment>
        );
      })
    );
  }


  return (
    <div className={classes}>
      {_buildAccordion()}
    </div>
  );
};

export default Accordion;

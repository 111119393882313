import React                                                from "react";
import cx                                                   from "classnames";
import useEventListener                                     from "hooks/global/useEventListener";
import { useScript }                                        from "hooks/global/useScript";
import { killProcess }                                      from "utils/events/emitters/killProcess";
import { processInfo as buttonHasBeenClickedProcessInfo }   from "utils/events/listeners/buttonHasBeenClicked";
import { processInfo as correctionCheckboxesProcessInfo }   from "utils/events/listeners/correctedCheckboxes";
import { processInfo as htmlCardHasBeenClickedProcessInfo } from "utils/events/listeners/htmlCardHasBeenClicked";
import { processInfo as qrCardHasBeenClickedProcessInfo }   from "utils/events/listeners/qrHasBeenClicked";
import { processInfo as botStatus }                         from "utils/events/listeners/botStatus";
import "./WCIWrapper.scss";


const targetIdentifier: string                 = "wci-wrapper__target";
const botcoreURL: string                       = new URL("bundle", process.env.REACT_APP_BOTCORE_RESOURCES_URI).toString();
const scriptAttributes: Record<string, string> = {
  id:     "HMB",
  log:    "true",
  key:    process.env.REACT_APP_WCI_API_KEY || "",
  target: targetIdentifier,
};


interface IWCIWrapperProps {
  className: string
}


/**
 * WebChat Instance Wrapper
 * @param className
 * @constructor
 */
const WCIWrapper: React.FC<IWCIWrapperProps> = ({ className }) => {
  useScript(botcoreURL, scriptAttributes, killProcess);
  // @ts-ignore
  useEventListener(...buttonHasBeenClickedProcessInfo);
  // @ts-ignore
  useEventListener(...correctionCheckboxesProcessInfo);
  // @ts-ignore
  //useEventListener(...htmlCardHasBeenClickedProcessInfo);
  // @ts-ignore
  useEventListener(...qrCardHasBeenClickedProcessInfo);
  // @ts-ignore
  // useEventListener(...addLaterButtonProcessInfo);
  // @ts-ignore
  useEventListener(...botStatus);

  const classes = cx(
    "wci-wrapper",
    className,
  );

  return (
    <div className={classes}>
      <div id={targetIdentifier} className={targetIdentifier}/>
    </div>
  );
};

export default WCIWrapper;


import React, { useState }                                from "react";
import RocketIcon from "assets/img/rocket-launch.png";
import "./Evolution.scss";
import "./Evolution.media-700.scss";
import Skills from "components/Containers/Skills/Skills";
import Score from "components/Containers/Score/Score";
import DomainsWeb from "components/Containers/DomainsWeb/DomainsWeb";
import { useQuery } from "@apollo/client";
import { FEED_MATRIX_PROGRESSION_QUERY, FEED_TODAY_CONTENT_QUERY } from "requests/graphql/queries/progress";
import { RoutePath } from "constants/routePath";
import * as H from "history";
import { useHistory } from "react-router-dom";
import useWindowDimensions from "hooks/specific/useWindowDimension";
import { useTranslation } from 'react-i18next';

const Evolution: React.FC = () => {
  const history: H.History<H.LocationState> = useHistory();
  const [validContent, setValidContent] = useState<number | null>(null);
  const [invalidContent, setInvalidContent] = useState<number | null>(null);

  const {width} = useWindowDimensions();
  const { t, i18n } = useTranslation();

  useQuery(FEED_TODAY_CONTENT_QUERY, {
    fetchPolicy: "no-cache",
    onCompleted:function(data){
      setValidContent(data?.progress?.todayValid);
      setInvalidContent(data?.progress?.todayInvalid);
    }
  });

  function _renderMatrixName(){
    const {data} = useQuery(FEED_MATRIX_PROGRESSION_QUERY, {
      fetchPolicy: "no-cache",
    });
    return data?.progress?.matrix? data.progress.matrix.name : t('loading');
  }

  function _handleRedirect(pathname: string): void {
    history.push(pathname);
}


  return (
    <div className={"evolution"}>
      <div className={"evolution__content"}>
        <h1 className={"evolution__title"}>
          {t('views.evolution.title')}
          <img className={"evolution__title__rocket"} src={RocketIcon} alt="Rocket Icon"/>
        </h1>
        <h4 className={"evolution__matrixName"}>{t('views.evolution.training')} : {_renderMatrixName()}</h4>
        <div className={"evolution__card"}>
          <h2>{t('views.evolution.today')}</h2>
          <div className={"evolution__card__content"}>
            <div className={"evolution__card__content__today"}>
              <div className={"evolution__card__content__today__circle__good"}>{validContent !== null ? validContent : "..."}</div>
              <div className={"evolution__card__content__today__circle__bad"}>{invalidContent !== null ? invalidContent : "..."}</div>
              <div className={"evolution__card__content__today__good__text"}>👍<br/>{t('views.evolution.content')}{width < 800 ? <br/> : " "}{t('views.evolution.validated')}</div>
              <div className={"evolution__card__content__today__bad__text"}>👎<br/>{t('views.evolution.content')}{width < 800 ? <br/> : " "}{t('views.evolution.failed')}</div>
            </div>
          </div>
        </div>
        <div className={"evolution__card evolution__card__clickable evolution__card__column"} onClick={() => _handleRedirect(RoutePath.EvolutionProgress)}>
          <h2>{t('views.evolution.evolutionProgress')}</h2>
          <Score className={"evolution__card__content"} preview={true}/>
        </div>
        <div className={"evolution__card evolution__card__clickable evolution__card__column"} onClick={() => _handleRedirect(RoutePath.EvolutionDomain)}>
          <h2>{t('views.evolution.evolutionDomain')}</h2>
          <DomainsWeb className={"evolution__card__content"} preview={true}/>
        </div>
        <div className={"evolution__card evolution__card__clickable evolution__card__column"} onClick={() => _handleRedirect(RoutePath.EvolutionSkills)}>
          <h2>{t('views.evolution.evolutionSkills')}</h2>
          <Skills className={"evolution__card__content"} preview={true}/>
        </div>
        
      </div>
    </div>
  );
};

export default Evolution;

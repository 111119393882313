import React from "react";
import cx    from "classnames";
import "./Step.scss";

export interface IStepProps {
  className?: string,
  text: number|string,
  tooltip?: string, // TODO: implement ?
}

const Step: React.FC<IStepProps> = ({ className, text, tooltip }) => {

  const classes: string = cx(
    className,
    "step",
  );

  return (
    <div className={classes}>
      <span className={"step__text"}>
        {text}
      </span>
    </div>
  );
};

export default Step;

import { getWCIShadowRootSource } from "utils/wci/helpers/getWCIShadowRootSource";


/**
 * Get the last element of a parent by his classname
 * @param nodeParent
 * @param className
 * @returns {NodeListOf<Element>}
 */
function getLastElementByClassName(nodeParent: any, className: string) {
  const elementList = nodeParent.querySelectorAll(`.${className}`);
  if (elementList.length) {
    return elementList[elementList.length - 1];
  }
  return undefined;
}


/**
 * Apply disable style on HTML Card
 * @param target
 */
function disableHTMLCard(target: HTMLElement): void {
  target.classList.add("MessageContainer__htmlCard_disabled");
}


/**
 * Determines how to stop a video according to the video provider
 * @param target
 */
function stopVideo(target: HTMLElement): void {
  const $content: HTMLElement | null                  = target.querySelector(".HtmlCard__iframeWrapper_isVideo");
  const type: string | undefined                      = $content?.dataset?.videoProvider;
  const $iframe: HTMLIFrameElement | null | undefined = $content?.querySelector("iframe");

  switch (type) {
    case "vimeo":
      stopVimeoVideo($iframe);
      break;
    case "youtube":
      stopYoutubeVideo($iframe);
      break;
    case "dailymotion":
      stopDailymotionVideo($iframe);
      break;
  }
}


function stopVimeoVideo($iframe: HTMLIFrameElement | null | undefined): void {
  $iframe?.contentWindow?.postMessage("{\"method\":\"unload\"}", "*");
}


function stopYoutubeVideo($iframe: HTMLIFrameElement | null | undefined): void {
  $iframe?.contentWindow?.postMessage(JSON.stringify({
    event: "command",
    func:  "pauseVideo",
    args:  [],
  }), "*");
}


function stopDailymotionVideo($iframe: HTMLIFrameElement | null | undefined): void {
  $iframe?.contentWindow?.postMessage("pause", "*");
}


function eventListener(event: any) {
  console.debug("[Tiny] handleDisableHtmlCard() - qr has been clicked!");

  if (( event as any ).detail.targetValue.payload !== "TC_NEXT_STEP") {
    console.debug("[Tiny] handleDisableHtmlCard() - qr clicked is not a next step!");
  }

  const $webchatRoot: ShadowRoot | null | undefined = getWCIShadowRootSource();
  if (!$webchatRoot) {
    console.error("No shadow root found");
    return;
  }
  const targetNode   = $webchatRoot.querySelectorAll(".MessageList__container")[0];
  const lastHtmlCard = getLastElementByClassName(targetNode, "Message__container_isHtmlCard");
  if (lastHtmlCard) {
    disableHTMLCard(lastHtmlCard);
    stopVideo(lastHtmlCard);
  }
}


const eventName: string = "hmb_webchat_qr_has_been_clicked";

export const processInfo = [eventName, eventListener];

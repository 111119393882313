import React                             from "react";
import cx                                from "classnames";
import ReactSwitch, { ReactSwitchProps } from "react-switch";


interface ISwitch {
  className?: string,
}


/**
 * Switch Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param rest
 * @constructor
 * @return {React.FC<ISwitch>}
 */
const Switch: React.FC<ISwitch & ReactSwitchProps> = ({ className, ...rest }) => {

  const classes: string = cx(
    "switch",
    className,
  );

  return (
    // @ts-ignore
    <ReactSwitch
      onColor="#bd7bff"
      onHandleColor="#942cfc"
      handleDiameter={25}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 3px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48} {...rest} className={classes}
    />
  );
};

export default Switch;

import React        from "react";
import cx           from "classnames";
import "./UserProgress.media-850.scss"
import "./UserProgress.scss"
import { useLocation } from 'react-router-dom';
import { RoutePath } from "constants/routePath";
import Score from "components/Containers/Score/Score";
import DomainsWeb from "components/Containers/DomainsWeb/DomainsWeb";
import Skills from "components/Containers/Skills/Skills";
import { FEED_MATRIX_PROGRESSION_QUERY } from "requests/graphql/queries/progress";
import { useQuery } from "@apollo/client";
import { useTranslation } from 'react-i18next';


interface IUserProgress {
  className?: string,
}


/**
 * UserProgress Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IUserProgress>}
 */
const UserProgress: React.FC<IUserProgress> = ({ className }) => {

  const classes: string = cx(
    "user-progress",
    className,
  );
  const { t, i18n } = useTranslation();

  /**
   * Define the header to render
   */
  function _renderHeader(title: string = "", subtitle: string = "") {
    if (!title && !subtitle) return null;

    return (
        <div className={"user-progress__header"}>
            {
                !!title &&
                <span className="user-progress__title">
                  {title}
                </span>
            }
            {
                !!subtitle &&
                <span className="user-progress__subtitle">
                  {subtitle}
                </span>
            }
        </div>
    );
  }

  function _renderMatrixName(){
    const {data} = useQuery(FEED_MATRIX_PROGRESSION_QUERY, {
        fetchPolicy: "no-cache",
    });
    return (
        <div className={"user-progress__matrixName"}>
            {
                <span>{data?.progress?.matrix? data.progress.matrix.name : "\u00a0"}</span>
            }
        </div>
    );
  }


  function _renderComponent(){
    const currentPath = useLocation().pathname;

    let header: JSX.Element | null = null,
        content: JSX.Element | null = null,
        matrixName : JSX.Element | null = null;

        matrixName = _renderMatrixName();

    switch(currentPath){
      case RoutePath.EvolutionProgress:
        content = <Score className={"user-progress__score"}/>;
        header = _renderHeader(t('views.userProgress.evolutionProgress'));
        break;
      case RoutePath.EvolutionDomain:
        content = <DomainsWeb className={"user-progress__domains-web"}/>;
        header = _renderHeader(t('views.userProgress.evolutionDomain'));
        break;
      case RoutePath.EvolutionSkills:
        content = <Skills className={"user-progress__skills"}/>;
        header = _renderHeader(t('views.userProgress.evolutionSkills'), t('views.userProgress.evolutionSkillsSubTitle'));
        break;
    }
    return (
      <>
        {matrixName}
        {header}
        {content}
      </>
    )
  }

  return (
    <div className={classes}>
      {_renderHeader()}
      {_renderComponent()}
    </div>
  );
};

export default UserProgress;

import React, { useState }        from "react";
import cx           from "classnames";
import "./Streaming.scss"
import {useLoading} from "contexts/LoadingContext";
import { parseGetParams } from "utils/functions/parseGetParams";
import { useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { FEED_HAS_ACCESS_VIMEO_CONTENT } from "requests/graphql/queries/user";
import { useTranslation } from 'react-i18next';


interface IStreaming {
  className?: string,
}


/**
 * Streaming Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IStreaming>}
 */
const Streaming: React.FC<IStreaming> = ({ className }) => {

  const [pageLoading, setLoading] = useLoading();
  const [hasAccess, setHasAccess] = useState(false);
  const { t, i18n } = useTranslation();

  let argsError = false,
      urlError = false;

  function isValidURL(str:string) {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }

  const currentPath = useLocation().search;
  const args = parseGetParams(currentPath);
  const { url, access_token } = args;
  if (!url || !access_token) {
    argsError = true;
    setLoading(false);
  } else if (!isValidURL(url)) {
    urlError = true;
  }

  useQuery(FEED_HAS_ACCESS_VIMEO_CONTENT, {
    fetchPolicy: "no-cache",
    variables:args,
    onCompleted: function(data){
      setHasAccess(data.hasAccessVimeoContent);
      setLoading(false);
    }
  });

  const classes: string = cx(
    "streaming",
    className,
  );



  return (
    <div className={classes}>
      {
        hasAccess ? (
          <div className={"streaming-video"}>
            <iframe allowFullScreen className={"img-fluid video-custom-prev"} src={url}></iframe>
          </div>
        ) : <span/>
      }
      {
        !hasAccess && !argsError && !urlError ?(
          <div className={"streaming-error"}>
            {t('views.streaming.noAccess')}
          </div> 
        ) : <span/>
      }
      {
        argsError ? (
          <div className={"streaming-error"}>
            {t('views.streaming.error')}
          </div> 
        ) : <span/>
      }
      {
        urlError ? (
          <div className={"streaming-error"}>
            {t('views.streaming.wrongURL')}
          </div> 
        ) : <span/>
      }
    </div>
  );
};

export default Streaming;

import React         from "react";
import cx            from "classnames";
import Accordion, { IKey }     from "components/Commons/Accordion/Accordion";
import "./CGU.scss";
import { useTranslation } from 'react-i18next';


interface ICGU {
  className?: string,
}


/**
 * CGU Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ISkills>}
 */
const CGU: React.FC<ICGU> = ({ className }) => {

  const classes: string = cx(
    "cgu",
    className,
  );
  const { t, i18n } = useTranslation();

  const CGUResources: IKey[] = [
    {
      id: "purpose_and_scope",
      title: t('views.CGU.purpose_and_scope.title'),
      value: t('views.CGU.purpose_and_scope.value'),
    },
    {
      id: "definitions",
      title: t('views.CGU.definitions.title'),
      value: t('views.CGU.definitions.value'),
    },
    {
      id: "service_description",
      title: t('views.CGU.service_description.title'),
      value: t('views.CGU.service_description.value'),
    },
    {
      id: "requirements_prior_to_subscribing_to_the_service",
      title: t('views.CGU.requirements_prior_to_subscribing_to_the_service.title'),
      value: t('views.CGU.requirements_prior_to_subscribing_to_the_service.value'),
    },
    {
      id: "access_to_the_service_subscription_order_process",
      title: t('views.CGU.access_to_the_service_subscription_order_process.title'),
      value: t('views.CGU.access_to_the_service_subscription_order_process.value'),
    },
    {
      id: "obligations_of_the_subscriber",
      title: t('views.CGU.obligations_of_the_subscriber.title'),
      value: t('views.CGU.obligations_of_the_subscriber.value'),
    },
    {
      id: "pricing_conditions_of_the_service_subscription",
      title: t('views.CGU.pricing_conditions_of_the_service_subscription.title'),
      value: t('views.CGU.pricing_conditions_of_the_service_subscription.value'),
    },
    {
      id: "entry_into_force_and_duration_of_the_subscription",
      title: t('views.CGU.entry_into_force_and_duration_of_the_subscription.title'),
      value: t('views.CGU.entry_into_force_and_duration_of_the_subscription.value'),
    },
    {
      id: "responsabily_of_tiny_coaching",
      title: t('views.CGU.responsabily_of_tiny_coaching.title'),
      value: t('views.CGU.responsabily_of_tiny_coaching.value'),
    },
    {
      id: "major_force",
      title: t('views.CGU.major_force.title'),
      value: t('views.CGU.major_force.value'),
    },
    {
      id: "right_of_withdrawal",
      title: t('views.CGU.right_of_withdrawal.title'),
      value: t('views.CGU.right_of_withdrawal.value'),
    },
    {
      id: "privacy_policy_personal_data",
      title: t('views.CGU.privacy_policy_personal_data.title'),
      value: t('views.CGU.privacy_policy_personal_data.value'),
    },
    {
      id: "changes_to_the_service",
      title: t('views.CGU.changes_to_the_service.title'),
      value: t('views.CGU.changes_to_the_service.value'),
    },
    {
      id: "suspension_or_termination_of_the_subscription",
      title: t('views.CGU.suspension_or_termination_of_the_subscription.title'),
      value: t('views.CGU.suspension_or_termination_of_the_subscription.value'),
    },
    {
      id: "evidence_convention",
      title: t('views.CGU.evidence_convention.title'),
      value: t('views.CGU.evidence_convention.value'),
    },
    {
      id: "law_and_language_and_applicable_to_the_contract",
      title: t('views.CGU.law_and_language_and_applicable_to_the_contract.title'),
      value: t('views.CGU.law_and_language_and_applicable_to_the_contract.value'),
    },
  ];

  return (
    <div className={classes}>
      <Accordion keys={CGUResources}/>
    </div>
  );
};

export default CGU;

import i18n from 'i18next';
import translationFR from './fr/translation.json';
import translationEN from './en/translation.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
  fr: {
    translation:translationFR
  },
  en:{
    translation:translationEN
  } 
} as const;

i18n.use(LanguageDetector).use(initReactI18next).init({
  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
  resources,
  debug:true,
  nonExplicitSupportedLngs:true
});
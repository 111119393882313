/**
 * Note:
 * Loading Context prevent from multiple re-render when the whole application is loading
 */
import React, { useState } from "react";
import LoaderBackground    from "../components/Containers/LoaderBackground/LoaderBackground";


/**
 * Types
 */
type State = boolean | undefined;
type Dispatch = ( (bool: boolean) => void ) | undefined;


/**
 * Contexts
 */
const LoadingStateContext    = React.createContext<State>(undefined);
const LoadingDispatchContext = React.createContext<Dispatch>(undefined);


/**
 * Interface for global provider
 */
interface ILoadingProvider {
  children?: React.ReactNode,
  initialState?: boolean,
}


/**
 * Global Provider
 * @param children
 * @param initial
 * @constructor
 */
function LoadingProvider({ children, initialState = false }: ILoadingProvider): JSX.Element {
  const [loading, setLoading] = useState<boolean>(initialState);

  return (
    <LoadingStateContext.Provider value={loading}>
      <LoadingDispatchContext.Provider value={setLoading}>
        {children}
        {loading && <LoaderBackground/>}
      </LoadingDispatchContext.Provider>
    </LoadingStateContext.Provider>
  );
}


/**
 * State hook
 */
function useLoadingState(): State {
  const context: State = React.useContext(LoadingStateContext);
  if (context === undefined) {
    throw new Error("[Tiny] - useLoadingState must be used within a LoadingProvider");
  }
  return context;
}


/**
 * Dispatch hook
 */
function useLoadingDispatch(): Dispatch {
  const context: Dispatch = React.useContext(LoadingDispatchContext);
  if (context === undefined) {
    throw new Error("[Tiny] - useLoadingDispatch must be used within a LoadingProvider");
  }
  return context;
}


/**
 * Merge state and dispatch hooks
 */
function useLoading(): Array<any> {
  return [useLoadingState(), useLoadingDispatch()];
}


export { LoadingProvider, useLoading, useLoadingState, useLoadingDispatch };

import { LocalStorage } from "../../constants/localStorage";


export function getAuthToken(): string | null {
  return localStorage.getItem(LocalStorage.AuthToken);
}

export function removeAuthToken(): void {
  localStorage.removeItem(LocalStorage.AuthToken);
}


export function setAuthToken(token: string | null): void {
  // @ts-ignore
  localStorage.setItem(LocalStorage.AuthToken, token);
}

import { useEffect } from "react";


/**
 * Use script
 * @param url {string} - The source of the script
 * @param params {Record<string, string>} - The attributes to pass to the script
 * @param unmountCallback {function} - The unmount callback called when the useEffect is on unmount process
 * @param callback {function} - The callback called each time that the useEffect is played
 */
export function useScript(
  url: string,
  params: Record<string, string>,
  unmountCallback?: () => void,
  callback?: (script: HTMLScriptElement) => void,
) {
  useEffect(() => {
    const script: HTMLScriptElement = document.createElement("script");
    script.src                      = url;
    script.async                    = true;

    // Prevents cross origin error (cf. https://reactjs.org/docs/cross-origin-errors.html)
    if (process.env.NODE_ENV) {
      script.setAttribute("crossorigin", "true");
    }

    // Add attributes to our script tag
    for (const [attrName, attrValue] of Object.entries(params)) {
      script.setAttribute(attrName, attrValue);
    }

    // Add to the document
    document.body.appendChild(script);

    if (callback) callback(script);

    // unmount script w/ component
    return () => {
      document.body.removeChild(script);
      if (unmountCallback) unmountCallback();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params]);
}

import React                    from "react";
import cx                       from "classnames";
import Banner, { IBannerProps } from "components/Containers/Banner/Banner";
import { RoutePath }            from "constants/routePath";
import Footer from "../Footer/Footer";
import "./BannerWrapper.scss";

interface IBannerWrapper {
  path: string
}


/**
 * Wraps the banner and determines the classname associated
 * @param children
 * @param path
 * @constructor
 */
const BannerWrapper: React.FC<IBannerWrapper> = ({ children, path }) => {

  const classes: string = cx(
    {
      "home__banner":             path === RoutePath.Root,
      "pending-programs__banner": path === RoutePath.PendingPrograms,
      "evolution__banner":        path === RoutePath.Evolution,
      "cgu__banner":              path === RoutePath.CGU,
      "settings__banner":         path === RoutePath.Settings,
      "history__banner":          path === RoutePath.History,
      "user-progress__banner":    path === RoutePath.EvolutionDomain || 
                                  path === RoutePath.EvolutionProgress || 
                                  path === RoutePath.EvolutionSkills,
    },
  );


  function _getBannerProps(): IBannerProps {
    const props: IBannerProps = {};
    if (path === RoutePath.CGU) {
      props.nav = {
        home: false,
        back: true,
      };
    }
    return props;
  }


  return (
    <>
      <Banner  {..._getBannerProps()} className={classes}/>
      <div className={"general_wrapper"}>
        {children}
      </div>
      <Footer/>
    </>
  );
};

export default BannerWrapper;

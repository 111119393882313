/**
 * Classes allowed
 * TODO: change classes names
 */
enum Classes {
  Selected = "QuickReplies__itemWrapper_previouslySelected",
  Disabled = "QuickReplies__itemWrapper_disabled",
}


/**
 * Check if the button is already selected or disabled
 * @param button
 */
function isButtonAlreadySelected(button: HTMLElement) {
  return button.classList.contains(Classes.Selected) || button.classList.contains(Classes.Disabled);
}


/**
 * Event listener
 * @param event
 */
function eventListener(event: Event) {
  console.debug("[Tiny] Receive event hmb_webchat_button_has_been_clicked");
  let target: EventTarget & HTMLElement | null | undefined = ( event as CustomEvent )?.detail?.target;

  while (!target?.classList.contains("Button__component")) {
    target = target?.parentElement;
  }

  if (target && !isButtonAlreadySelected(target)) {
    const buttonWrapper: HTMLElement | null | undefined     = target?.parentElement;
    const buttonWrapperList: HTMLElement | null | undefined = buttonWrapper?.parentElement;

    buttonWrapper?.classList.add(Classes.Selected);

    // @ts-ignore
    for (const buttonWrapperChild of buttonWrapperList?.children) {
      if (buttonWrapperChild === buttonWrapper) continue;
      buttonWrapperChild.classList.add(Classes.Disabled);
    }
  }
}

const eventName: string = "hmb_webchat_button_has_been_clicked";

export const processInfo = [eventName, eventListener];

import React, { useState } from "react";
import cx from "classnames";
import "./Password.scss"
import { useLoading } from "contexts/LoadingContext";
import { parseGetParams } from "utils/functions/parseGetParams";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "hooks/specific/useWindowDimension";
import MoodSimple from "assets/img/mood-simple.png";
import { RoutePath } from "constants/routePath";

import * as H                            from "history";
import { useHistory }                    from "react-router-dom";

import { ReactComponent as WarningIcon } from "assets/img/icons/warning.svg";
import { ReactComponent as PaperPlaneIcon } from "assets/img/icons/paper-plane.svg";
import { ReactComponent as CheckIcon } from "assets/img/icons/check.svg";

import { ReactComponent as MessengerIcon } from "assets/img/icons/channels/messenger.svg";
import { ReactComponent as TeamsIcon } from "assets/img/icons/channels/teams.svg";
import TinyIcon from "assets/img/icons/channels/tiny.png";
import { useMutation } from "@apollo/client";
import { SEND_MAIL_PASSWORD } from "requests/graphql/mutations/sendMailPassword";
import Loader from "components/Commons/Loader/Loader";
import { RESET_PASSWORD } from "requests/graphql/mutations/resetPassword";
import { useTranslation } from 'react-i18next';
import DOMPurify from "dompurify";
import axios from "axios";



interface IPassword {
  className?: string,
}


/**
 * Password Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IPassword>}
 */
const Password: React.FC<IPassword> = ({ className }) => {

  //Déclaration des states
  const [pageLoading, setLoading]                   = useLoading();
  const [mailToReset, setMailToReset]               = useState<string>("")
  const [mailToResetError, setMailToResetError]     = useState<string | null>(null)
  const [password, setPassword]                     = useState<string>("")
  const [passwordConf, setPasswordConf]             = useState<string>("")
  const [passwordResetError, setPasswordResetError] = useState<string | null>(null)
  const [loadingPassword, setLoadingPassword]       = useState<boolean | null>(false)
  const { t, i18n } = useTranslation();


  //Récupération des informations de navigation
  const history: H.History<H.LocationState>  = useHistory();
  const currentSearch = useLocation().search;
  const args = parseGetParams(currentSearch);
  const currentPath = useLocation().pathname

  //Gestion de la page actuelle
  const STATE_RESET = 1;
  const STATE_MAIL_SENT = 2;
  const STATE_MAKE_NEW_PASSWORD = 3;
  const STATE_PASSWORD_RESETTED = 4;
  let currentState :any = null;

  switch (currentPath) {
    case RoutePath.Forgot_Password:
      currentState = (STATE_RESET);
      break;
    case RoutePath.Forgot_Password_Mail_Sent:
      currentState = (STATE_MAIL_SENT);
      break;
    case RoutePath.Reset_Password:
      if(!args.email || !args.token){
        currentState = (STATE_RESET);
        break;
      }
      currentState = (STATE_MAKE_NEW_PASSWORD);
      break;
    case RoutePath.Reset_Password_Done:
      currentState = (STATE_PASSWORD_RESETTED);
      break;
  }
  setLoading(false);

  function resetPassword(email:any,token:any,password:any){
    password = password.replace(/[\n\r]/gm, '')
    //Echappement des " et \ pour ne pas que ca casse la requete
    .replace(/\\/gm, '\\\\')
    .replace(/"/gm, '\\"');
    let url = process.env.REACT_APP_GRAPHQL_ENDPOINT + `?query=mutation resetPassword 
    {
      resetPassword(
        email: "`+encodeURIComponent(email)+`"
        validationCode: "`+encodeURIComponent(token)+`"
        password: "`+encodeURIComponent(password)+`"
      )
    }`;
    setLoadingPassword(true);
    axios.get(url).then(function(response){
      if(response?.data?.errors){
        setPasswordResetError(response?.data?.errors[0]?.message);
      }
      else{
        history.push(RoutePath.Reset_Password_Done);
      }
    }).catch(function(error){
      setPasswordResetError(error);
    }).finally(function(){
      setLoadingPassword(false);

    })
  }

  function sendMailMutation(mail:any): any {
    let url = process.env.REACT_APP_GRAPHQL_ENDPOINT + "?query=mutation sendMail { forgotPassword(email: \""+encodeURIComponent(mail)+"\" )}";
    setLoadingPassword(true);
    axios.get(url).then(function(response){
      if(response?.data?.errors){
        setPasswordResetError(response?.data?.errors[0]?.message);
      }
      else{
        history.push(RoutePath.Forgot_Password_Mail_Sent);
      }
    }).catch(function(error){
      setPasswordResetError(error);
    }).finally(function(){
      setLoadingPassword(false);

    })
  }

  function __handleBackButton() {
    setLoading(true);
    history.push(RoutePath.Root);
  }

  function _handleChannelClick(url:string) {
    window.open(url, "_blank");
}

  function __handleResetPassword() {
    setPasswordResetError(null);
    if(password === ""){
      setPasswordResetError(t('views.password.error.setPassword'));
      return;
    }
    if(passwordConf === ""){
      setPasswordResetError(t('views.password.error.setConfPassword'));
      return;
    }
    if(password != passwordConf){
      setPasswordResetError(t('views.password.error.setSamePassword'));
      return;
    }
    if(password.length < 6){
      setPasswordResetError(t('views.password.error.passwordLength'));
      return;
    }
    resetPassword(
      args.email,
      args.token,
      password
    );
  }

  function __handleSendMail() {
    setMailToResetError(null);
    sendMailMutation(mailToReset);
  }

  const classes: string = cx(
    "password",
    className,
  );

  const { height } = useWindowDimensions();

  function __makeErrorBox(message:string){
    return (
      <div className={"password__error"}>
        <div className={"password__error__icon"}>
          <WarningIcon/>
        </div>
        <div className={"password__error__content"}>
          {message}
        </div>
      </div>
    )
  }

  function __renderPasswordState() {
    switch (currentState) {
      case STATE_RESET:
      default:
        return (
          <div className={"password__content_wrapper"}>
            {
              mailToResetError ? __makeErrorBox(mailToResetError) : null
            }
            <div className={"password__input_wrapper"}>
              <input placeholder={t('views.password.resetPlaceholder')} type="text" className={"password__input"} onChange={e=>{setMailToReset(e.target.value)}}></input>
            </div>
            <div onClick={__handleSendMail} className={"password__button"}>{loadingPassword ? (
              <Loader className={"loader-thin loader-small"}></Loader>)
              : t('views.password.confirm')}</div>
            <div onClick={__handleBackButton} className={"password__back"}>{t('views.password.back')}</div>
          </div>
          );
      case STATE_MAIL_SENT:
        return (
          <div className={"password__content_wrapper"}>
            <div className={"password__message"}>
              <div className={"password__message__icon"}>
                <PaperPlaneIcon/>
              </div>
              <div className={"password__message__content"}
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(t('views.password.confirmMail')),
              }}>
              </div>
            </div>
            <div onClick={__handleBackButton} className={"password__back"}>{t('views.password.back')}</div>
          </div>
        );
   
        case STATE_MAKE_NEW_PASSWORD:
          return (
            <div className={"password__content_wrapper"}>
              {
                passwordResetError ? __makeErrorBox(passwordResetError) : null
              }
              <div className={"password__input_wrapper"}>
                <input placeholder={t('views.password.resetState.newPasswordPlaceholder')} type="password" className={"password__input"} onChange={e=>{setPassword(e.target.value)}}></input>
              </div>
              <div className={"password__input_wrapper"}>
                <input placeholder={t('views.password.resetState.confNewPasswordPlaceholder')} type="password" className={"password__input"} onChange={e=>{setPasswordConf(e.target.value)}}></input>
              </div>
              <div onClick={__handleResetPassword} className={"password__button"}>{loadingPassword ? (
              <Loader className={"loader-thin loader-small"}></Loader>)
              : t('views.password.confirm')}</div>
              <div onClick={__handleBackButton} className={"password__back"}>{t('views.password.back')}</div>
            </div>
          )
   
        case STATE_PASSWORD_RESETTED:
          return (
            <div className={"password__content_wrapper"}>
              <div className={"password__message"}>
                <div className={"password__message__icon"}>
                  <CheckIcon />
                </div>

                <div className={"password__message__content"}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(t('views.password.confirmResetMailTemp')),
                  }}>
                </div>
              </div>
              {/* <div className={"password__connexion"}>👇 {t('views.password.connect')} 👇</div>
              <div className={"password__redirection"}>
                <div className={"password__redirection__item"} onClick={__handleBackButton}>
                  <div className={"password__redirection__item__logo"}>
                    <img src={TinyIcon} alt="Tiny logo"/>
                  </div>
                  <span>{t('views.password.application')}<br/>Tinycoaching</span>
                </div>
                <div className={"password__redirection__item"}  onClick={e => _handleChannelClick("https://teams.microsoft.com/l/chat/0/0?users=28:6532f08b-0ac3-4708-a671-92b3fe30a93b")}>
                  <div className={"password__redirection__item__logo"}>
                    <TeamsIcon />
                  </div>
                  <span>Teams</span>
                </div>
                <div className={"password__redirection__item"} onClick={e => _handleChannelClick("https://m.me/TinycoachingOfficiel")} >
                  <div className={"password__redirection__item__logo"}>
                    <MessengerIcon />
                  </div>
                  <span>Messenger</span>
                </div>
              </div> */}
            </div>
          );
    }
  }

  return (
    <div style={{ height }} className={classes}>
      <div className={"password__wrapper"}>
        <img className={"password__mood"} src={MoodSimple} alt="Tiny Mood Icon" />
        {__renderPasswordState()}

      </div>
    </div>
  );
};

export default Password;

import { gql } from "@apollo/client";

const FEED_LATER_USER_CONTENT_IDS_QUERY = gql`
    query GetNumberOfUserLaterContent {
        userLaterContents {
            id
        }
    }
`;

const FEED_LATER_USER_CONTENT_QUERY = gql`
  query GetUserLaterContent {
      userLaterContents {
          id
          title
          tcContent {
              id
              title1
              length
              iframe_audio
              iframe_media
              iframe_text
              iframe_video
              catchy_phrase
          }
      }
  }
`;

export {
  FEED_LATER_USER_CONTENT_IDS_QUERY,
  FEED_LATER_USER_CONTENT_QUERY,
};

import React                       from 'react';
import ReactDOM                    from 'react-dom';
import App                         from 'components/App/App';
import reportWebVitals             from './reportWebVitals';
import { defineBrowserViewHeight } from "./utils/browserViewHeightManager";
import './index.scss';

import './i18n/config';

defineBrowserViewHeight();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

if ('serviceWorker' in navigator && process.env.NODE_ENV !== 'production' && 'Notification' in window) {
  console.log('Trying to register custom sw');
  navigator.serviceWorker
    .register('./firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { parseGetParams } from "utils/functions/parseGetParams";
import { loadPendingContents } from "../emitters/loadPendingContents";
import i18n from "i18next";
import { changeBotLanguage } from "../emitters/changeBotLanguage";

let already_called :any = null;
const delay = 25;

/**
 * Event listener
 * @param event
 */
function eventListener(event: any) {
  //Si le bot est monté
  if(event?.detail?.newCase === "engagement_case_completed"){
    //On vérifie qu'on a pas déjà exécuté le script
    if(!already_called || (Date.now() - already_called > delay)){
      setTimeout(function(){
        //console.log("changeBotLanguage engagement_case_completed")
        changeBotLanguage(i18n.language)
      },10);

      already_called = Date.now();
      //On récupère les contextes
      const args = parseGetParams(window.location?.search);
      //S'il y'a un contexte, on appelle l'action correspondante
      if(args.context){
        switch(args.context){
          case "load_pending_content":
            setTimeout(function(){
              loadPendingContents();
            },10)
            break;
        }
        window.history.replaceState({}, document.title, "/");
      }
    }
    else{
      //console.log("hmb_webchat_flow_case_changed already called")
    }
  }
}

const eventName: string = "hmb_webchat_flow_case_changed";

export const processInfo = [eventName, eventListener];

import { gql } from "@apollo/client";

const CHOOSE_LATER_CONTENT = gql`
    mutation chooseLaterContent($contentId: Int!) {
      chooseLaterContent(contentId: $contentId)
    }
`;

export {
  CHOOSE_LATER_CONTENT,
}

/**
 * Send an event to the webchat instance to kill the process
 * @param callback {function}
 */
export function loadPendingContents(callback?: () => void) {
  //console.log("[Tiny] Send event load_pending_content");
  window.dispatchEvent(
    new CustomEvent(
      "hmb_webchat_send_payload",
      {
        detail: {
          channelKey: process.env.REACT_APP_WCI_API_KEY,
          action: "load_pending_content", // <string> l'identifiant de l'action
        }
      }
  ));
  if (callback) callback();
}

import { gql } from "@apollo/client";

const PATCH_HANDICAP_MUTATION = gql`
    mutation handicapParameter($type: Int!) {
        handicapParameter(type: $type)
    }
`;

export {
  PATCH_HANDICAP_MUTATION,
}

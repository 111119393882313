import { EventName }    from "constants/eventName";
import useEventListener from "hooks/global/useEventListener";


/**
 * Use logout hook
 *
 * Fired when the event "logout" is handled
 *
 * @param callback
 */
export function useLogout(callback: (event: any) => void): void {
  console.debug("[Tiny] - useLogout()");
  useEventListener(EventName.Logout, callback);
}

import React, { useEffect}    from "react";
import WCIWrapper from "components/Containers/WCIWrapper/WCIWrapper";
import "./Home.scss";
import cx         from "classnames";
import useWindowDimensions from "hooks/specific/useWindowDimension";
import Animation from "components/Containers/Animation/Animation";
import MobileModal from "components/Containers/MobileModal/MobileModal";

import MoodEyes from "assets/img/mood-eyes.gif"
import MoodBlink from "assets/img/mood-blink.gif"
import { useTranslation } from 'react-i18next';

import { isMobile } from 'react-device-detect';



interface IHome {
  authed: boolean,
  className?: string,
}

const Home: React.FC<IHome> = ({ authed, className }) => {

  const classes: string = cx(
    "home",
    { "home--authed": authed },
    className,
  );
  const {height, width} = useWindowDimensions();
  const { t, i18n } = useTranslation();



  useEffect(() => {

  }, [])



  /*Using this for troubleshooting the mobile height with browser header*/
  const authedDivHeight = (height - 90 - 60) + "px" // size of the banner & footer
  const unAuthedDivHeight = (height - 60) + "px" // size of the banner & footer
  const mobileDivHeight= height +"px"


  function __renderPage (){

    return (
      <div style={{height:authed ? authedDivHeight : isMobile ? mobileDivHeight: unAuthedDivHeight}} className={classes}>
        <Animation 
        title={t('views.home.animation_welcome.title')}
        text={t('views.home.animation_welcome.text')}
        buttonText={t('views.home.animation_welcome.buttonText')}
        image={MoodEyes}
        className="animation_welcome"
        withProgression={false}
        ></Animation>
        <Animation 
        title={t('views.home.animation_success.title')}
        text={t('views.home.animation_success.text')}
        buttonText={t('views.home.animation_success.buttonText')}
        image={MoodBlink}
        className="animation_success"
        withProgression={true}
        ></Animation>
        {/*<MobileModal></MobileModal>*/}
        <WCIWrapper className={"home__wci"}/>
      </div>
    );
  }


  return (
    __renderPage()
  );
};

export default Home;

import React from "react";
import cx    from "classnames";
import "./Loader.scss";


interface ILoader {
  className?: string,
}


/**
 * Loader Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<ISkills>}
 */
const Loader: React.FC<ILoader> = ({ className }) => {

  const classes: string = cx(
    "loader",
    className,
  );

  return (
    <div className={classes}/>
  );
};

export default Loader;

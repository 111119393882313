// TODO : set typescript correctly on it when you have the time

import { EventName } from "constants/eventName";
import { getWCIShadowRootSource } from "utils/wci/helpers/getWCIShadowRootSource";


function applyCorrectionProcess(childNodes: any, correctedCheckboxes: string[], isRadio = false) {
  if (childNodes.length && correctedCheckboxes.length) {
    for (let i = 0 ; i < childNodes.length ; i++) {
      const $checkboxButton   = childNodes[i];
      const checkboxButtonPos = i + 1; // in array without starting at 0
      // const checkboxButtonHasSelected = $checkboxButton.classList.contains("CheckboxButton__container_selected")

      let isInvalid; // in case where the user selected the wrong checkbox

      for (let j = 0 ; j < correctedCheckboxes.length ; j++) {
        const correctedValueIndex = correctedCheckboxes[j];
        // const statement1 = checkboxButtonPos === correctedValueIndex && checkboxButtonHasSelected; // selected and valid
        // const statement2 = checkboxButtonPos === correctedValueIndex && !checkboxButtonHasSelected; // not selected but valid

        // @ts-ignore
        // eslint-disable-next-line eqeqeq
        const statement1 = checkboxButtonPos == correctedValueIndex; // no strict equality operator

        // if (statement1 || statement2) {
        if (statement1) {
          isInvalid = false;
          addValidClassName($checkboxButton, isRadio);
          break;
        }

        isInvalid = true;
      }

      if (isInvalid) {
        addInvalidClassName($checkboxButton, isRadio);
      }
    }
  }
}


function addValidClassName($element: HTMLElement, isRadio: boolean) {
  if (isRadio) $element.classList.add("RadioButton__container_valid");
  else $element.classList.add("CheckboxButton__container_valid");
}


function addInvalidClassName($element: HTMLElement, isRadio: boolean) {
  if (isRadio) $element.classList.add("RadioButton__container_invalid");
  else $element.classList.add("CheckboxButton__container_invalid");
}

function determineIfSuccess(childNodes: any, correctedCheckboxes: number[], classToCheck:string){
  if (childNodes.length && correctedCheckboxes.length) {
    let isGlobalGoodAnswer = true;
    for (let i = 0 ; i < childNodes.length ; i++) {
      const child=childNodes[i];
      const childValue = i+1;
      const userAnswer = child.classList.contains(classToCheck) //Does user said yes or no
      //User said yes and it's a the good answer or user said no and it as not the good answer (so success anyway)
      const goodAnswer = (userAnswer && correctedCheckboxes.includes(childValue)) || (!userAnswer && !correctedCheckboxes.includes(childValue))
      if(!goodAnswer){
        isGlobalGoodAnswer = false;
      }
    }
    if(isGlobalGoodAnswer){
      window.dispatchEvent(new Event(EventName.RefreshProgressBar));
      window.dispatchEvent(new Event(EventName.ShowAnimationSuccess));
      //document.getElementsByClassName("animation_success")[0].classList.remove("animation__hidden");
    }
  }
}


function eventListener(event: any) {
  console.debug("Receive event correctedCheckboxes");
  const $webchatRoot: ShadowRoot | null | undefined = getWCIShadowRootSource();
  //
  window.dispatchEvent(new Event(EventName.RefreshLaterContent))
  // If we have corrected checkbox array
  if ($webchatRoot && event && Array.isArray(event.detail) && event.detail.length) {
    const $messageList = $webchatRoot.querySelectorAll(".MessageList__container")[0];
    const childNodes   = $messageList.childNodes;
    // (Inverted) Loop over each child nodes of the message list
    for (let i = childNodes.length ; i > 0 ; i--) {
      const $child = childNodes[i - 1] as HTMLElement;
      // Find the last message QR list
      if ($child.classList.contains("Message__container_isCheckboxType")) {
        const $buttonWrapper = $child.getElementsByClassName("CheckboxMessage__checkboxWrapper")[0];
        applyCorrectionProcess($buttonWrapper.childNodes, event.detail);
        determineIfSuccess($buttonWrapper.childNodes, event.detail, "CheckboxButton__container_selected");
        return;
      }
      else if ($child.classList.contains("Message__container_isRadioType")) {
        const $buttonWrapper = $child.getElementsByClassName("RadioMessage__wrapper")[0];
        applyCorrectionProcess($buttonWrapper.childNodes, event.detail, true);
        determineIfSuccess($buttonWrapper.childNodes, event.detail, "RadioButton__container_selected");
        return;
      }
    }
  }
}


const eventName: string = "correctedCheckboxes";


export const processInfo = [eventName, eventListener];

export function getRandomColor() {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export function getTinyColor(index:number){
  const colorList:any = ["#00EECA", "#FF404C", "#FF7D00", "#00BBCE", "#FC90B7", "#FFBE00", "#BF33B5"];
  return colorList[index%colorList.length];
}

import React, { useMemo }                from "react";
import cx                                from "classnames";
import { ReactComponent as ArticleLogo } from "assets/img/icons/article.svg";
import { ReactComponent as VideoLogo }   from "assets/img/icons/video.svg";
import { ReactComponent as ClockLogo }   from "assets/img/icons/clock.svg";
import { IframeType }                    from "requests/graphql/enums/iframeType";
import Loader                            from "../../Commons/Loader/Loader";

import * as H                            from "history";
import { useHistory }                    from "react-router-dom";

import "./PendingProgramCard.scss";
import "./PendingProgressCard.media-450.scss";
import "./PendingProgramCard.media-600.scss";

import { CHOOSE_LATER_CONTENT } from "requests/graphql/mutations/chooseLaterContent";
import {useMutation} from "@apollo/client";
import { RoutePath } from "constants/routePath";
import { useTranslation } from 'react-i18next';


export interface PendingProgram {
  id: string,
  title: string,
  content: {
    id: string,
    duration: string,
    title: string,
    shortPhrase: string,
    type: IframeType,
    previewLink?: string,
  }
}


interface IPendingProgramCard {
  className?: string,
  program: PendingProgram,
}


/**
 * PendingProgramCard Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param program
 * @constructor
 * @return {React.FC<IPendingProgramCard>}
 */
const PendingProgramCard: React.FC<IPendingProgramCard> = ({ className, program }) => {

  /**
   * Slice our title w/ 60 characters max and add text-ellipsis on it if necessary
   */
  const memoizedTitle: string = useMemo(() => {
    return ( program?.title?.slice(0, 60) ) + ( program?.title?.length > 60 ? "..." : "" );
  }, [program?.title]);

  const history: H.History<H.LocationState>  = useHistory();

  const [LATER_CONTENT, {loading}] = useMutation(CHOOSE_LATER_CONTENT, {
    onCompleted(data){
        history.push(
          {
            pathname: RoutePath.Root,
            search: '?context=load_pending_content'
          });
    }
  });
  const { t, i18n } = useTranslation();


  function __determineTypeText(type : IframeType){
    switch(type){
      case IframeType.Audio:
        return t('containers.pendingProgramCard.type.audio');
      case IframeType.Text:
        return t('containers.pendingProgramCard.type.text');
      case IframeType.Video:
        return t('containers.pendingProgramCard.type.video');
      case IframeType.Media:
      default:
        return t('containers.pendingProgramCard.type.media');
    }
  }

  function __renderImg(){
    let src = "https://hmb-clients-storage.s3-eu-west-1.amazonaws.com/tiny-coaching/video-default-logo.svg";
    if(
      program.content.type === IframeType.Audio ||
      program.content.type === IframeType.Text  ||
      program.content.type === IframeType.Media
      ){
        return <img src={src} alt="card preview"></img>
      }
      else{
        if(program.content.previewLink && program.content.previewLink.length > 0){
          src = program.content.previewLink;
          //Si lien youtube, on force l'embeding
          if(src.includes("youtu.be") || src.includes("youtube.com")){
            let srcSplited = src.split("/");
            let videoId = srcSplited[srcSplited.length - 1];
            //On supprime le /watch?v= si il existe pour ne garder que l'id
            if(srcSplited.includes("watch?v=")){
              videoId = videoId.substring(8)
            }
            src = "https://www.youtube.com/embed/" + videoId;
          }
        }
        return <iframe title={program.content.title} allowFullScreen src={src} frameBorder="0" allow="encrypted-media;accelerometer;autoplay"></iframe>
      }
  }

  function __handleStartButton(){
    LATER_CONTENT({variables:{
      contentId:parseInt(program.content.id)
    }});
  }


  const classes: string = cx(
    "pending-program-card",
    className,
    {
      "pending-program-card-audio"    : program.content.type === IframeType.Audio,
      "pending-program-card-article"  : program.content.type === IframeType.Text,
      "pending-program-card-video"    : program.content.type === IframeType.Video,
      "pending-program-card-media"    : program.content.type === IframeType.Media
    }
  );

  return (
    <div className={classes}>
      <div className={"pending-program-card__content"}>
        <div className={"pending-program-card__content__image"}>
          {__renderImg()}
        </div>
        <div className={"pending-program-card__content__subtitle"}>{memoizedTitle}</div>
        <div className={"pending-program-card__content__link"}>&nbsp;</div>
        <div className={"pending-program-card__content__header"}>
          <h2 className={"pending-program-card__content__header__title"}>{program?.content?.title}</h2>
          <h5 className={"pending-program-card__content__header__description"}>{program?.content?.shortPhrase}</h5>
        </div>
        <div className={"pending-program-card__content__duration"}>
          <ClockLogo/>
          <p>{program?.content?.duration}</p>
        </div>
        <div className={"pending-program-card__content__type"}>
          {
            program?.content?.type === IframeType.Video
            ? <VideoLogo/>
            : <ArticleLogo/>
          }
          <p>{__determineTypeText(program?.content?.type)}</p>
        </div>
      </div>
      <div className={"pending-program-card__footer"}>
        {
          loading ? (
          <Loader className={"loader-thin loader-small loader-right"}></Loader>)
           : 
        <button type={"button"} onClick={__handleStartButton} className={"pending-program-card__start-btn"}>{t('containers.pendingProgramCard.start')}</button>

        }
      </div>
    </div>
  );
};

export default PendingProgramCard;

/**
 * Check file size
 * @param file {File}
 * @param max {number} In Kilo octet
 */
export function parseGetParams(queryString: String): any {
  let query:any = {};
  let pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
    for (let i = 0; i < pairs.length; i++) {
      let pair = pairs[i].split('=');
      const key = pair[0];
      pair.shift();
      const value = pair.length > 0 ? pair.join("=") : '';

      query[decodeURIComponent(key)] = decodeURIComponent(value);
    }
    return query;
}

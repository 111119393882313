import { useEffect }       from "react";
import { ApolloError }     from "@apollo/client";


type QueryError = ApolloError | undefined;


function logError(error: QueryError) {
  console.error("[Tiny] - ", error?.message, { errorStack: error });
}


/**
 * Used to render in web console one or many errors from an Apollo query
 * @param error
 */
export function useQueryError(error: Array<QueryError> | QueryError): void {
  useEffect(() => {
    if (!error) return;
    if (Array.isArray(error)) {
      for (const e of error) {
        if (!e) continue;
        logError(e);
      }
    }
    else logError(error);
  }, [error]);
}

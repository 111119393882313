import { gql } from "@apollo/client";

const LANGUAGE_MUTATION = gql`
  mutation ($languageId: Int) {
    changeLanguage(languageId: $languageId){
        email
        language{
            id
            name
        }
    }
  }
`;

export {
  LANGUAGE_MUTATION,
}

import React, { useState } from "react";
import cx from "classnames";
import "./Animation.scss";
import BannerStepper from "../BannerStepper/BannerStepper";
import useEventListener from "hooks/global/useEventListener";
import { EventName } from "constants/eventName";



export interface IAnimationProps {
    title:string,
    text:string,
    buttonText:string,
    className:string,
    image:string,
    withProgression:boolean
}

const Animation: React.FC<IAnimationProps> = ({title,text,buttonText,className,image,withProgression}) => {

    const [buttonClicked, setButtonClicked] = useState<boolean>()
    const [displayAnimation, setDisplayAnimation] = useState<boolean>()

      //  this.state = {className:"animation__hidden", buttonClicked:false};

    function handleClick(){
        setButtonClicked(true)
        setTimeout(() =>{
            setDisplayAnimation(false)
        },10)
    }

    function handleBlur(){
        setButtonClicked(false)
    }

    const classes: string = cx(
        "animation",
        className,
    );
    
    useEventListener(className === "animation_success" ? EventName.ShowAnimationSuccess : EventName.ShowAnimationWelcome, (event) => {
        setDisplayAnimation(true)
    });

    const buttonClass = buttonClicked ? "animation__button animation__button__clicked" : "animation__button";


    return displayAnimation ? (
        <div className={classes}>
            <div className={"animation__wrapper"}>
                <img className={"animation__image"} src={image} alt="Clin d'oeil"></img>
                <div className={"animation__title"}>{title}</div>
                <div className={"animation__text"}>{text}</div>
                {withProgression ? (
                    <div className={"animation__progression"}><BannerStepper/></div>
                ) : null}
                <div className={buttonClass} onBlur={handleBlur} onClick={handleClick}>{buttonText}</div>
            </div>
        </div>
    ) : (<></>);
    
}

export default Animation;

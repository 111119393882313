import React                                       from "react";
import cx                                          from "classnames";
import { useQuery }                                from "@apollo/client";
import GoalRadarChart, { IGoalRadarChartDataItem } from "components/Commons/Charts/Radar/GoalRadar/GoalRadar";
import { FEED_DOMAINS_PROGRESSION_QUERY }          from "requests/graphql/queries/progress";
import { getTinyColor }                          from "utils/functions/randomColor";
import "./DomainsWeb.scss";
import { useTranslation } from 'react-i18next';


interface IDomainsWeb {
  className?: string,
  preview?: boolean

}


/**
 * DomainsWeb Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param type
 * @constructor
 * @return {React.FC<IDomainsWeb>}
 */
const DomainsWeb: React.FC<IDomainsWeb> = ({ className, preview }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, error } = useQuery(FEED_DOMAINS_PROGRESSION_QUERY, {
    fetchPolicy: "no-cache",
  });
  const { t, i18n } = useTranslation();


  const classes: string = cx(
    "domains-web",
    className,
    {
      "domains-web-preview" : preview
    }
  );


  /**
   * Returns an array of values corresponding to the completion rate of each domain.
   * @return {Array<IGoalRadarChartDataItem>}
   */
  function _defineChartData(): IGoalRadarChartDataItem[] {
    if (!data?.progress?.domains) {
      console.error("[Tiny] - No data provided for generating the props for the GoalRadarChart component");
    }

    return ( data?.progress?.domains || [] ).map((domain: any, index:number) => {
      const donePercentage: number = Math.ceil(( domain.level * 100 ) / domain.goal);
      return {
        label: domain.title,
        value: donePercentage,
        color: getTinyColor(index),
      };
    });
  }


  const parsedData = _defineChartData(); // don't memoize yet because it don't reload excessively

  return (
    <div className={classes}>
      {
        loading &&
        <span className="domains-web__loading-message">
          {t("loading")}
        </span>
      }
      {
        !loading && parsedData &&
        <>
          <GoalRadarChart preview={preview} data={parsedData.map(({ value, color }) => ( { value, color } ))}/>
          {preview ? null : 
            <div className={"domains-web__legend"} >
              <h4>{t("containers.domainsWeb.legend")}</h4>
              {
                parsedData.map(({ label, color }) => (
                  <h5><span className={"domains-web__colored-label-tick"} style={{ backgroundColor: color }}/>{label}</h5>
                  ))
              }
              <div className={"domains-web__details"}>{t("containers.domainsWeb.description")}</div>
            </div>
          }
        </>
      }
    </div>
  );
};

export default DomainsWeb;

import React, { MutableRefObject, useRef } from "react";
import Chart                               from "chart.js";
import cx                                  from "classnames";
import { useMount }                        from "hooks/global/useMount";
import "./ScoreDoughnut.scss";


export enum ScoreDoughnutDefaultColors {
  Completed   = "#FF8008",
  Uncompleted = "#820CFC",
}


interface IScoreDoughnutChart {
  className?: string,
  chunks?: number,
  score: number,
  total: number,
  colors?: {
    completed: string,
    uncompleted: string,
  }
  preview?: boolean
}


/**
 * DoughnutChart Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param chunks
 * @param score
 * @param total
 * @param colors
 * @constructor
 * @return {React.FC<IScoreDoughnutChart>}
 */
const ScoreDoughnutChart: React.FC<IScoreDoughnutChart> = ({ className, chunks, score, total, colors, preview }) => {
  const ref: MutableRefObject<any> = useRef();

  useMount(() => {
    const ctx = ref.current.getContext("2d");

    new Chart(ctx, {
      type:    "doughnut",
      data:    {
        datasets: [
          {
            data:             Array.from(Array(chunks)).map(() => 1), // create a repetitive array w/ the total of chunks
            backgroundColor:  _defineBackground(score, total),
            borderColor:      "rgb(244, 246, 248)",
            borderWidth:      5,
            hoverBorderColor: "transparent",
          },
        ],
      },
      options: {
        responsive:       true,
        cutoutPercentage: 88,
        legend:           {
          display: false,
        },
        tooltips:         {
          enabled: false,
        },
      },
    });
  });


  /**
   * Create an array of color based on score and on chunks base
   * @param score
   * @param total
   */
  function _defineBackground(score: number, total: number): Array<string> {
    const scorePercentage: number = Math.ceil(( score * 100 ) / total);
    // Calculates on chunks base
    const completedScore: number  = Math.ceil(( ( chunks as number ) * scorePercentage ) / 100);
    return Array.from(Array(chunks)).map((value, index) => ( index < completedScore ? colors!.completed : colors!.uncompleted )); // not undefined because wwe set it on default props
  }


  const classes: string = cx(
    "doughnut-score-chart",
    className,
    {
      "doughnut-score-chart-preview" : preview
    }
  );

  return (
    <div className={classes}>
      <div className={"doughnut-score-chart__canvas-container"}>
        <canvas
          id="doughnut-score-chart__canvas"
          ref={ref}
        />
      </div>
      <div className={"doughnut-score-chart__division"}>
        <p>{score}</p>
        <hr/>
        <p>{total}</p>
      </div>
    </div>
  );
};

ScoreDoughnutChart.defaultProps = {
  chunks: 20,
  colors: {
    completed:   ScoreDoughnutDefaultColors.Completed,
    uncompleted: ScoreDoughnutDefaultColors.Uncompleted,
  },
};

export default ScoreDoughnutChart;

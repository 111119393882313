export enum RoutePath {
  Root                        = "/",
  All                         = "*",
  PendingPrograms             = "/pending-programs",
  Evolution                   = "/evolution",
  CGU                         = "/cgu",
  Settings                    = "/settings",
  History                     = "/history",

  EvolutionSkills             = "/userSkills",
  EvolutionDomain             = "/userDomainWeb",
  EvolutionProgress           = "/userProgress",

  Streaming                   = "/streaming",

  Forgot_Password             = "/forgot-password",
  Forgot_Password_Mail_Sent   = "/forgot-password-sent",
  Reset_Password              = "/reset-password",
  Reset_Password_Done         = "/reset-password-done",
  Bug_Report         = "/bug-report",
}


/**
 * Ignored paths (user authed or not)
 * Note: use a duplicate enum is more efficient than looping to the first one (cf. algorithm complexity)
 */
export enum IgnoredRoutePath {
  Root                        = "/",
  CGU                         = "/cgu",
  Streaming                   = "/streaming",
  
  Forgot_Password             = "/forgot-password",
  Forgot_Password_Mail_Sent   = "/forgot-password-sent",
  Reset_Password              = "/reset-password",
  Reset_Password_Done         = "/reset-password-done",
  Bug_Report         = "/bug-report",

}


/**
 * Inactive paths
 * Used to disable some path of the application
 * TODO: with custom browser history
 */
export enum InactiveRoutePath {

}

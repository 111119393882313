import React               from "react";
import { ApolloProvider }  from "@apollo/client";
import Router              from "components/Router/Router";
import CookieBanner        from "components/Containers/CookieBanner/CookieBanner";
import { client }          from "config/graphql";
import { AuthProvider }    from "contexts/AuthContext";
import { LoadingProvider } from "contexts/LoadingContext";


function App(): JSX.Element {
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <LoadingProvider initialState={true}>
          <Router/>
          {/*<CookieBanner/>*/}
        </LoadingProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}


export default App;

import { LocalStorage } from "constants/localStorage";

/**
 * Send an event to the webchat instance to kill the process
 * @param callback {function}
 */
export function changeBotLanguage(language: string, callback?: () => void) {
  const storedLanguage = localStorage.getItem(LocalStorage.HMBLastLanguage);

  //if storedLanguage is not null and not the same than the language to send
  //(if null -> it's the first time so the language set by the navigator is ok)
  const isNewLanguage = storedLanguage === null || storedLanguage !== language;

  localStorage.setItem(LocalStorage.HMBLastLanguage, language);

  language = language.substring(0,2);
  console.log(language);
  const toSend = { detail: language };
  console.log(toSend);

  if (isNewLanguage) {
    setTimeout(function(){
      console.log("hmb_webchat_set_locale");
      window.dispatchEvent(new CustomEvent("hmb_webchat_set_locale", toSend));
    }, 500) 

  }
  else{
    setTimeout(function(){
      console.log("hmb_webchat_set_ui_language");
      window.dispatchEvent(new CustomEvent("hmb_webchat_set_ui_language", toSend));
    }, 500)
  }


  if (callback) callback();
}

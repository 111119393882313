import { gql } from "@apollo/client";

const FEED_USER_ACCOUNT_QUERY = gql`
    query GetAccountInfo {
        user {
            fullName
            image {
                url
            }
        }
    }
`;

const FEED_STEPPER_QUERY = gql`
    query GetStepperInfo {
        user {
            session
        }
    }
`;

const FEED_CGU_HAS_BEEN_ACCEPTED_QUERY = gql`
    query GetUserCGUInfo {
        user {
            cgu
        }
    }
`;

const FEED_USER_LANGUAGE = gql`
    query GetUserLanguage {
        user {
            language{
                id
                name
                shortName
            }
        }
    }
`;

const FEED_SETTINGS_QUERY = gql`
    query GetUserHandicaps {
        user {
            handicaps {
                daltonien
                dyslexie
                malvoyant
                malentendant
            }
            allowNotification
        }
    }
`;

const FEED_HAS_ACCESS_VIMEO_CONTENT = gql`
    query($access_token :String, $url :String) {
        hasAccessVimeoContent(access_token : $access_token,url :$url)
    }
`;

export {
  FEED_USER_ACCOUNT_QUERY,
  FEED_STEPPER_QUERY,
  FEED_CGU_HAS_BEEN_ACCEPTED_QUERY,
  FEED_SETTINGS_QUERY,
  FEED_HAS_ACCESS_VIMEO_CONTENT,
  FEED_USER_LANGUAGE
};

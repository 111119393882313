import {ReactComponent as EnglishIcon} from "assets/img/icons/countries/en.svg";
//import {ReactComponent as SpainIcon} from "assets/img/icons/countries/es.svg";
import {ReactComponent as FrenchIcon} from "assets/img/icons/countries/fr.svg";

interface ICountry {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
  id: string
  shortId: string
  name: string
  dbId: number,
  checked?: boolean
}

const CountriesAvailable : Array<ICountry>  = [
  {
    icon:           FrenchIcon,
    name:           "Français",
    id:             "fr-FR",
    shortId:        "fr",
    dbId:           1
  },
  {
    icon:           EnglishIcon,
    name:           "English",
    id:             "en-US",
    shortId:        "en",
    dbId:           2
  }/*,
  {
    icon:           SpainIcon,
    name:           "Español",
    id:             "es",
    dbId:           3
  }*/
];

export default CountriesAvailable;
import { ApolloClient, ApolloLink, InMemoryCache } from "@apollo/client";
import { isUpload }                                from "./helpers";
import { httpLink }                                from "./links/http";
import { authLink }                                from "./links/auth";
import { uploadLink }                              from "./links/upload";


const requestLink = ApolloLink.from([
  authLink,
  httpLink,
]);

const link = ApolloLink.split(isUpload, uploadLink, requestLink);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

import { gql } from "@apollo/client";


const CREATE_TRELLO_BUG_REPORT_CARD = gql`
    mutation formTrello(
        $name: String!,
        $email: String!,
        $organisation: String!,
        $formation: String!,
        $details: String!,
        $browsers: String,
        $devices: String!,
        $applications: String
    ) {
    formTrello(
        name : $name, 
        email : $email, 
        organisation : $organisation,
        formation : $formation,
        details : $details,
        browsers : $browsers,
        devices : $devices,
        applications : $applications
    )
  }
`;

export {
    CREATE_TRELLO_BUG_REPORT_CARD
}

import { EventName }    from "constants/eventName";
import useEventListener from "hooks/global/useEventListener";


/**
 * Use login hook
 *
 * Fired when the event "login" is handled
 *
 * @param callback
 */
export function useLogin(callback: (event: any) => void): void {
  console.debug("[Tiny] - useLogin()");
  useEventListener(EventName.Login, callback);
}

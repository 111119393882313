import { gql } from "@apollo/client";

const PATCH_PARAMETER_MUTATION = gql`
    mutation notificationParameter($push: Boolean!) {
      notificationParameter(push: $push)
    }
`;

export {
  PATCH_PARAMETER_MUTATION,
}

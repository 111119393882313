import React              from "react";
import cx                                     from "classnames";
import PendingProgramCard, { PendingProgram } from "../PendingProgramCard/PendingProgramCard";
import "./PendingProgramWrapper.scss";


interface IPendingProgramWrapper {
  className?: string,
  program: PendingProgram
}


/**
 * PendingProgramWrapper Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param program
 * @constructor
 * @return {React.FC<IPendingProgramWrapper>}
 */
const PendingProgramWrapper: React.FC<IPendingProgramWrapper> = ({ className, program }) => {

  const classes: string = cx(
    "pending-program-wrapper",
    className,
  );

  return (
    <div className={classes}>
      <img src="https://hmb-clients-storage.s3-eu-west-1.amazonaws.com/tiny-coaching/tiny-coaching-webchat-lp-smile.svg" alt="avatar" className={"pending-program-wrapper__avatar"}/>
      <PendingProgramCard className={"pending-program-wrapper__card"} program={program}/>
    </div>
  );
};

export default PendingProgramWrapper;

import React                                  from "react";
import cx                                     from "classnames";
import { ReactComponent as ChevronRightIcon } from "assets/img/icons/chevron-right-regular.svg";
import Switch                                 from "components/Commons/Switch/Switch";
import { RoutePath }                          from "constants/routePath";
import uniqid                                 from "uniqid";
import "./SettingsItem.scss";
import * as H                                 from "history";
import { useHistory }                         from "react-router-dom";


export interface ISettingsItem {
  className?: string,
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
  id?: string,
  title: string,
  description?: string,
  redirectionPath?: RoutePath,
  redirectionNewWindowPath?: string,
  fillIcon?: boolean,
  checked?:boolean,
  type:string,
  onCheck?: (id: any, status: boolean) => void
}


/**
 * SettingsItem Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param question
 * @param description
 * @constructor
 * @return {React.FC<ISettingsItem>}
 */
const SettingsItem: React.FC<ISettingsItem> = ({
                                                 children,
                                                 className,
                                                 id,
                                                 title,
                                                 description,
                                                 icon: Icon,
                                                 redirectionPath,
                                                 redirectionNewWindowPath,
                                                 fillIcon,
                                                 checked = false,
                                                 onCheck,
                                                 type,
                                               }) => {
  const history: H.History<H.LocationState>                     = useHistory();
  const _id: string           = id || uniqid();

  const classes: string = cx(
    "settings-item",
    "settings-item--pointer",
    className,
  );


  function _handleCheck(_checked: boolean, event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent, id: string): void {
    console.debug("handle check");
    if (onCheck) onCheck!(_id, _checked);
  }


  function _handleRedirect() {
    if (redirectionPath) history.push(redirectionPath);
    if (redirectionNewWindowPath) window.open(redirectionNewWindowPath, "_blank");
    if ((type === "language" || type === "switch") && onCheck) onCheck(_id, !checked);
  }

  return (
    <div className={classes} onClick={_handleRedirect}>
      <Icon className={`settings-item__icon ${fillIcon ? "settings-item__icon__fill" : ""}`}/>
      <h5>{title}</h5>
      {
        !!description && <p>{description}</p>
      }
      {
        type === "switch" || type === "language"
        ? <Switch className={"settings-item__switch-btn"} checked={checked} onChange={_handleCheck}/>
        : <ChevronRightIcon className={"settings-item__redirection-icon"}/>
      }
      {children}
    </div>
  );
};

export default SettingsItem;

import React, { MutableRefObject, useRef } from "react";
import cx                                  from "classnames";
import Chart                               from "chart.js";
import { useMount }                        from "hooks/global/useMount";
import "./GoalRadar.scss";


export interface IGoalRadarChartDataItem {
  label?: string,
  value: number,
  color?: string,
}

interface IGoalRadarChart {
  className?: string,
  data: IGoalRadarChartDataItem[],
  preview?: boolean
}


/**
 * GoalRadarChart Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param data
 * @constructor
 * @return {React.FC<IGoalRadarChart>}
 */
const GoalRadarChart: React.FC<IGoalRadarChart> = ({ className, data, preview }) => {
  const ref: MutableRefObject<any> = useRef();

  useMount(() => {
    const ctx = ref.current.getContext("2d");
    console.debug(data)
    new Chart(ctx, {
      type: "radar",
      data: {
        labels: data.map(e => (e.label || e.value)),
        datasets: [
          {
            label: "tiny-goal-radar-chart",
            data: data.map(e => e.value),
            fill: true,
            backgroundColor: "#9229FF",
            borderColor: "#e2e2e2",
            pointBackgroundColor: "rgb(255, 99, 132)",
            pointBorderColor: "#fff",
            pointBorderWidth: 0,
            pointRadius: 0,
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(255, 99, 132)"
          }
        ]
      },
      options: {
        responsive: true,
        elements: { line: { tension: 0, borderWidth: 0 } },
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scale: {
          ticks: {
            beginAtZero: true,
            max: 100,
            display: false
          },
          gridLines: {
            circular: true
          },
          pointLabels: {
            fontColor: data.map(e => (e.color || "#000")),
            fontSize: 22,
          },
          angleLines: {
            display: true,
            color: "#820CFC"
          }
        }
      }
    });
  });

  const classes: string = cx(
    "goal-radar-chart",
    className,
    {
      "goal-radar-chart-preview" : preview
    }
  );

  return (
    <div className={classes}>
      <div className={"goal-radar-chart__canvas-container"}>
        <canvas
          id="goal-radar-chart__canvas"
          ref={ref}
        />
      </div>
    </div>
  );
};

export default GoalRadarChart;

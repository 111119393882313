import React                                  from "react";
import cx                                     from "classnames";
import { RoutePath }                          from "constants/routePath";
import "./NavItem.scss";
import "./NavItem.media-450.scss";
import * as H                                 from "history";
import { useHistory, useLocation }                         from "react-router-dom";


export interface INavItem {
  className?: string,
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>,
  title?: string,
  redirectionPath?: RoutePath,
  counter?: number
}


/**
 * NavItem Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @param question
 * @param description
 * @constructor
 * @return {React.FC<INavItem>}
 */
const NavItem: React.FC<INavItem> = ({
    className,
    title,
    icon: Icon,
    redirectionPath,
    counter
  }) => {
  const history: H.History<H.LocationState>  = useHistory();
  const currentPath = useLocation().pathname;

  const classes: string = cx(
    "nav-item",
    className,
    {
      "active":redirectionPath === currentPath
    }
  );

  function _handleRedirect() {
    if (redirectionPath) history.push(redirectionPath);
  }

  return (
    <div className={classes} onClick={_handleRedirect}>
      <div className={"nav-item__icon-wrapper"}>
        <Icon className={`nav-item__icon`}></Icon>
        {
          counter != null ? (
            <div className={"nav-item__counter"}>
              <span className={"nav-item__text"}>
                { counter ?? 0 }
              </span>
            </div>) : <span/>
        }
      </div>

      {
        title ? (
          <div className={`nav-item__title`}>{title}</div>
        ) : <span/>
      }
      

    </div>
  );
};

export default NavItem;

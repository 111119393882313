import React, {  useState } from "react";
import cx from "classnames";
import "./BugReport.scss"
import { useLoading } from "contexts/LoadingContext";
import { parseGetParams } from "utils/functions/parseGetParams";
import { useLocation } from "react-router-dom";
import useWindowDimensions from "hooks/specific/useWindowDimension";
import MoodSimple from "assets/img/mood-simple.png";
import { RoutePath } from "constants/routePath";
import * as H                            from "history";
import { useHistory }                    from "react-router-dom";
import { ReactComponent as WarningIcon } from "assets/img/icons/warning.svg";
import { useMutation } from "@apollo/client";
import AddTaskIcon from '@mui/icons-material/AddTask';
import {TextField, Chip,Stack} from '@mui/material';
import Button from '@mui/material/Button';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import customTheme from "config/theme/palette";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
// import CREATE_TRELLO_CARD_MUTATION
import { CREATE_TRELLO_BUG_REPORT_CARD } from "requests/graphql/mutations/formTrello";
import { ThemeProvider } from "@emotion/react";
import { setTimeout } from "timers";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


interface IBugReport {
  className?: string,
  name?: string,
  email?: string
}


/**
 * BugReport Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IBugReport>}
 */
const BugReport: React.FC<IBugReport> = ({ className }) => {

  

  //Déclaration des states
  const [pageLoading, setLoading]                   = useLoading();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");


  // ----- DONNÉES QUI SERONT ENVOYÉES AU BACK-END----- //

  const [organisation, setOrganisation] = useState<string>("");
  const [formation, setFormation] = useState<string>("");
  const [problemDescription, setProblemDescription] = useState<string>("");

  const [checkedDevices, setCheckedDevices] = useState<Array<string>>(new Array(0)) 
  const [checkedBrowsers, setCheckedBrowsers] = useState<Array<string>>(new Array(0))
  const [checkedCanals, setCheckedCanals] = useState<Array<string>>(new Array(0))
  const [filesToUpload, setFilesToUpload] = useState<Array<File>>([]);
  const [renderErrorBox, setRenderErrorBox] = useState<Boolean>(false);
  const [errorBoxMessage, setErrorBoxMessage] = useState<string>("");
  const [formSubmitted, setFormSubmitted] = useState<Boolean>(false);
  const [imagesToDisplay, setImagesToDisplay] = useState<Array<string>>([]);

  // ----- DONNÉES POUR LA VERIFICATION DES CHAMPS ----- //
  const [firstInput, setFirstInput] = useState<Boolean>(true)

  //Récupération des informations de navigation
  const history: H.History<H.LocationState>  = useHistory();
  const currentSearch = useLocation().search;
  const args = parseGetParams(currentSearch);
  const currentPath = useLocation().pathname
  const { t, i18n } = useTranslation();

  const DEVICES = [t('views.bugReport.devices.mobile'),t('views.bugReport.devices.computer'),t('views.bugReport.devices.laptop'),t('views.bugReport.devices.tablet')]
  const BROWSERS = ["Chrome","Firefox","Safari","Microsoft Edge", t('views.bugReport.other')]
  const CANALS = ["Google Play","App Store","Messenger","Microsoft Teams",t('views.bugReport.other')]

  //Gestion de la page actuelle
  let currentState :any = null;
  

  const [createTrelloCard, {}] = useMutation(CREATE_TRELLO_BUG_REPORT_CARD,{
    onCompleted: (response) =>{
      let jsonResponse = response['formTrello'];
      let newCardId= JSON.parse(jsonResponse)["id"];
      postImages(newCardId)
        .then( () => setFormSubmitted(true));
    }
  });
  const addAttachmentTrello :string = `mutation($image: String!,$cardId : String!){
      formTrelloAttachment(
          image : $image,
          cardId : $cardId
      )
  }`;
  setLoading(false);

  const __allInputsEntered = () =>{
    const condition =
      name != "" && 
      organisation != "" &&
      formation != "" &&
      problemDescription != "" &&
      checkedDevices.length > 0 &&
      (checkedBrowsers.length > 0 == true || checkedCanals.length > 0 == true)
      
    return condition
  }
  const __formValidated = () =>{
    const condition = __emailIsValid(email) && __allInputsEntered()
    return condition
  }
  const __handleSubmit  = async () => {
    setFirstInput(false)

    if (__formValidated()){
      const mutationJson = {
        name : name, 
        email : email, 
        organisation : organisation,
        formation : formation,
        details : problemDescription,
        devices : checkedDevices.join(", "),
        browsers : checkedBrowsers.join(", "),
        applications : checkedCanals.join(", "),
      }

      createTrelloCard({
        variables : mutationJson
      })

    }else{
      let errorMessage = ""
      !__allInputsEntered() ?
        !(name != "" && 
        organisation != "" &&
        formation != "" &&
        problemDescription != "") ?
          errorMessage = t('views.bugReport.errors.missing_inputs')
        :
          checkedDevices.length == 0 ?
            errorMessage = t('views.bugReport.errors.missing_devices')
          :
              errorMessage = t('views.bugReport.errors.missing_browser_or_app')
      : !__emailIsValid(email) ?
            errorMessage = t('views.bugReport.errors.invalid_email')
            :
            errorMessage = t('views.bugReport.errors.default')


      setErrorBoxMessage(errorMessage)
      setRenderErrorBox(true);
      setTimeout(() => setRenderErrorBox(false), 3000);
    }
  }

  const postImages = async (newCardId : string) =>{


    // Operation
    const op: Record<string, any> = {
      query: addAttachmentTrello,
      variables: {
        image: "image",
        cardId : newCardId.toString()
      },
    };

    // Prepare multi-part
    for (let file of filesToUpload){
      const formData: FormData = new FormData();
      formData.set("query", op.query);
      formData.set("variables", JSON.stringify(op.variables));
      formData.append(op.variables.image, file);
      formData.append(op.variables.cardId, newCardId);
      
      // @ts-ignore and send
      await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
        method: "POST",
        body: formData,
      })
  }
  }
  function __handleBackButton() {
    setLoading(true);
    history.push(RoutePath.Root);
  }

  const classes: string = cx(
    "bugreport",
    className,
  );

  const { height } = useWindowDimensions();

  function __makeErrorBox(message:string){
    return (
      <div className={"bugreport__error"}>
        <div className={"bugreport__error__icon"}>
          <WarningIcon/>
        </div>
        <div className={"bugreport__error__content"}>
          {message}
        </div>
      </div>
    )
  }

  const __containsElement = (element: any, array : Array<any>) =>{
    return array.find(e => e == element) ? true : false
  }

  const __handleElementSelect = (key : string,value : any, array : Array<any>) =>{
    switch (key) {
      case "devices":
        !__containsElement(value,array) ?
          setCheckedDevices(checkedDevices.concat(value))
        :
          setCheckedDevices(checkedDevices.filter(device => device != value))
        break;
      case "browsers":
        !__containsElement(value,array) ?
          setCheckedBrowsers(checkedBrowsers.concat(value))
        :
          setCheckedBrowsers(checkedBrowsers.filter(browser => browser != value))
        break;
      case "canals":
        !__containsElement(value,array) ?
          setCheckedCanals(checkedCanals.concat(value))
        :
          setCheckedCanals(checkedCanals.filter(canal => canal != value))
        break;
    
      default:
        break;
    }
  }
  
  const __emailIsValid = (email:string) => {
    return email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const Input = styled('input')({
    display: 'none',
  });
  const __handleFileUploadChange = (event : any) => {
    try {
      let files = event.target.files
      if (files.length > 0){
        
        setFilesToUpload(filesToUpload.concat(files[0]))
        const reader = new FileReader();
        
        reader.onloadend = () => {
          setImagesToDisplay(imagesToDisplay.concat(reader.result as string));
        };
        reader.readAsDataURL(files[0])

      }
    } catch (error:any) {
      __makeErrorBox(error.message)
    }
  }

  const __getPlaceHolderText = (index:any, attribute :any) => !(attribute || firstInput)? t('views.bugReport.' + index) + "  *" : t('views.bugReport.' + index)


  const __removeFile = (index:number) => {
    setFilesToUpload(filesToUpload.filter((_,i) => i != index))
    setImagesToDisplay(imagesToDisplay.filter((_,i)=>i != index))
  }


  const errorIcon = <NewReleasesIcon color="error"/>
  function __renderPageState() {
        if (formSubmitted){
          return (
            <div className={"bugreport__content_wrapper"}>
              <div className={"bugreport__message"}>
                <div className={"bugreport__message__icon"}>
                  <AddTaskIcon/>
                </div>
                <div className={"bugreport__message__content"} dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(t('views.bugReport.confirmMessage')),
                }}>
                </div>
              </div>
              <div onClick={__handleBackButton} className={"bugreport__back"}>{t('views.bugReport.back')}</div>
            </div>
          );
        } 
        return (
          <ThemeProvider theme={customTheme}>

          <div className={"bugreport__content_wrapper"}>
          {renderErrorBox && __makeErrorBox(errorBoxMessage)}

            <div style={{display:'flex', justifyContent: 'center'}}>
              <strong className="bugreport__title">{t('views.bugReport.formIntroduction')}</strong>
            </div>
            <div className={"bugreport__input_wrapper"}>
              <input id="name_input" placeholder={__getPlaceHolderText("name", name)} type="text" className={name ? "bugreport__input": firstInput? "bugreport__input" : "bugreport__input_invalid"} onChange={e=>{setName(e.target.value)}}></input>
              {!(name || firstInput) && <NewReleasesIcon color="error"/>}
            </div>
            <div className={"bugreport__input_wrapper"}>
              <input id="email_input" placeholder={__getPlaceHolderText("email", email)} type="text" className={email ? "bugreport__input": firstInput? "bugreport__input" : "bugreport__input_invalid"} onChange={e=>{setEmail(e.target.value)}}></input>
              {!(email || firstInput) && <NewReleasesIcon color="error"/>}
            </div>
            <div className={"bugreport__input_wrapper"}>
              <input id="organisation_input" placeholder={__getPlaceHolderText("organisation",organisation)} type="text" className={organisation ? "bugreport__input": firstInput? "bugreport__input" :"bugreport__input_invalid"} onChange={e=>{setOrganisation(e.target.value)}}></input>
              {!(organisation || firstInput) && <NewReleasesIcon color="error"/>}
            </div>
            <div className={"bugreport__input_wrapper"}>
              <input id="formation_input" placeholder={__getPlaceHolderText("formation",formation)} type="textarea" className={formation ? "bugreport__input": firstInput? "bugreport__input" :"bugreport__input_invalid"} onChange={e=>{setFormation(e.target.value)}}></input>
              {!(formation || firstInput) && <NewReleasesIcon color="error"/>}
            </div>

              {/* DEVICES*/}
              
              <strong className="bugreport__title">{__getPlaceHolderText("checkedDevices",checkedDevices)}</strong>
              <Stack direction="row" sx={{my : 3
                , flex: 1, flexWrap:'wrap', gap: 0.5, justifyContent: "center"}}>
                {DEVICES.map( e => (
                  <Chip clickable key={e} label={e} color={__containsElement(e,checkedDevices)? 'primary' : 'secondary'}
                  onClick={() => __handleElementSelect("devices",e,checkedDevices)} sx={{height :"40px", borderRadius:"30px", mx:1}}
                  />
                  ))}
              </Stack>

              {/* BROWSERS */}
              <strong className="bugreport__title">{__getPlaceHolderText("checkedBrowsers",checkedBrowsers)}</strong>
              <Stack direction="row" sx={{my : 3
                , flex: 1, flexWrap:'wrap', gap: 0.5, justifyContent: "center"}}>
                {BROWSERS.map( e => (
                  <Chip key={e} label={e} color={__containsElement(e,checkedBrowsers)? 'info' : 'secondary'} clickable 
                  onClick={() => __handleElementSelect("browsers",e,checkedBrowsers)} sx={{height :"40px", borderRadius:"30px", mx:1}}/>
                  ))}
              </Stack>

            {/* OTHER APPS */}
            <strong className="bugreport__title">{__getPlaceHolderText("checkedCanals",checkedCanals)}</strong>
            <Stack direction="row" sx={{my : 3
              , flex: 1, flexWrap:'wrap', gap: 0.5, justifyContent: "center"}}>

              {CANALS.map( e => (
                <Chip key={e} label={e} color={__containsElement(e,checkedCanals)? 'warning' : 'secondary'}
                clickable sx={{height :"40px", borderRadius:"30px", mx:1}}onClick={() => __handleElementSelect("canals",e,checkedCanals)}/>
                ))}
            </Stack>
            <strong className="bugreport__title">{t('views.bugReport.details')}</strong>

            <TextField
              label={__getPlaceHolderText("problemDescription",problemDescription)} id="standard-multiline-static" onChange={e=>{setProblemDescription(e.target.value)}} multiline
              error={firstInput == false && problemDescription == ""}
              InputLabelProps={{
                style: {
                  paddingLeft: 50
                }
              }}
              style={{paddingLeft: 50,paddingRight: 50,paddingTop: 10,paddingBottom: 10,marginTop: 30,marginBottom: 30,width: '70%',background: "white",
              borderRadius: '40px',outline: "none",border: 'none',marginLeft: '5%',marginRight: "5%",textEmphasisColor: "InfoBackground"}}
              InputProps={{ disableUnderline: true,endAdornment: firstInput == false && problemDescription == "" ?errorIcon: "" }} rows={4} defaultValue={problemDescription}variant="standard"
              >
                <NewReleasesIcon/>
              </TextField>
            <strong className="bugreport__title">{t('views.bugReport.image')}</strong>
            <label style={{marginTop : 10, marginBottom : 10}}>
              <Input accept="image/*" id="contained-button-file" type="file" onChange={__handleFileUploadChange}/>
              <Button variant="contained" style={{color: '#9229FF', background:'#C8D6D4'}} component="span">
                {imagesToDisplay.length == 0 ? <ImageIcon /> : <AddIcon/>}
              </Button>
            </label>
              <div className="uploaded_images">
                {imagesToDisplay.length>0 && imagesToDisplay.map((image,index)=> (
                    <div className="uploaded_image_content">
                      <img width="100" height="100" src={image} alt="" id="img" className="img" onClick={() =>window.open(image)}/>
                      <div onClick={()=>__removeFile(index)} className={"bugreport__back"}>{t('views.bugReport.delete')}</div>
                    </div>
                  ))
                }
              </div>

                <Button sx={{width:"80%",borderRadius: "30px", mt: 5, mb:5
              }} onClick={__handleSubmit} variant="contained" color="primary">
                  {t('views.bugReport.confirm')}
                </Button>
                <div onClick={__handleBackButton} className={"bugreport__back"}>{t('views.bugReport.back')}</div>
          </div>
          </ThemeProvider>
        );
  }

  return (
    <div style={{ height }} className={classes}>
      <div className={"bugreport__wrapper"}>
        <img className={"bugreport__mood"} src={MoodSimple} alt="Tiny Mood Icon" />
        {__renderPageState()}

      </div>
    </div>
  );
};

export default BugReport;

import React                            from "react";
import cx                               from "classnames";
import { useQuery }                     from "@apollo/client";
import ScoreDoughnutChart               from "components/Commons/Charts/Doughnut/ScoreDoughnut/ScoreDoughnut";
import { FEED_SCORE_PROGRESSION_QUERY } from "requests/graphql/queries/progress";
import "./Score.scss";
import "./Score.media-550.scss";
import { useTranslation } from 'react-i18next';


interface IScore {
  className?: string,
  preview?: boolean
}


/**
 * Score Functional Component
 * @param {string} className - used to set a class on a higher element tag
 * @constructor
 * @return {React.FC<IScore>}
 */
const Score: React.FC<IScore> = ({ className, preview }) => {
  const { data, loading, error } = useQuery(FEED_SCORE_PROGRESSION_QUERY, {
    fetchPolicy: "no-cache",
  });
  const { t, i18n } = useTranslation();

  console.debug({ data, error });

  const classes: string = cx(
    "score",
    className,
    {
      "score-preview" : preview
    }
  );

  return (
    <div className={classes}>
      {
        loading &&
        <span className="score__loading-message">
          {t('loading')}
        </span>
      }
      {
        !loading && data?.progress?.score &&
        <>
          <ScoreDoughnutChart total={data?.progress?.score?.goalScore} score={data?.progress?.score?.userScore} preview={preview}/>
          {
            preview ? null :
          
            <div className={"score__scoreboard"}>
              <div className={"score__scoreboard-goal-item"}>
                <h5>{t('containers.score.goal')}</h5>
                <p>{t('containers.score.goalText')} <span className="score__scoreboard-points">{data?.progress?.score?.goalScore}</span> %</p>
              </div>
              <div className={"score__scoreboard-score-item"}>
                <h5>{t('containers.score.score')}</h5>
                <p>{t('containers.score.scoreText')} <span className="score__scoreboard-points">{data?.progress?.score?.userScore}</span> %</p>
              </div>
            </div>
          }
        </>
      }

    </div>
  );
};

export default Score;

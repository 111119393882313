import React from "react";
import cx    from "classnames";
import "./Button.scss";


type IButton = {
  className?: string,
  text: string,
} & React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>


const Button: React.FC<IButton> = ({ className, text, ...rest }) => {

  const classes: string = cx(
    className,
    "button",
  );

  return (
    <button {...rest} className={classes}>
      {text}
    </button>
  );
};

export default Button;

/**
 * Send an event to the webchat instance to kill the process
 * @param callback {function}
 */
export function killProcess(callback?: () => void) {
  console.debug("[Tiny] Send event hmb_webchat_kill_process");
  window.dispatchEvent(
    new CustomEvent(
      "hmb_webchat_kill_process",
      {
        detail: {
          channelKey: process.env.REACT_APP_WCI_API_KEY,
        }
      }
    ));
  if (callback) callback();
}

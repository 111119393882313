import { gql } from "@apollo/client";


const DAY_CONNECTION = gql`
  mutation dayConnection(
    $location: String!
    $latitude: Float!
    $longitude: Float!
    $browser: String!
    $browser_version: String!
    $device: String!
    $os: String!
    $os_version: String!
    $userAgent: String!
    $firebaseToken: String
  ) {
    dayConnection(
      location: $location
      latitude: $latitude
      longitude: $longitude
      browser: $browser
      browser_version: $browser_version
      device: $device
      os: $os
      os_version: $os_version
      userAgent: $userAgent
      firebaseToken: $firebaseToken
    )
  }
`;

export {
  DAY_CONNECTION,
}

import React                               from "react";
import { useQuery }                        from "@apollo/client";
import { ReactComponent as DateRangeIcon } from "assets/img/icons/date-range.svg";
import { PendingProgram }                  from "components/Containers/PendingProgramCard/PendingProgramCard";
import PendingProgramWrapper               from "components/Containers/PendingProgramWrapper/PendingProgramWrapper";
import { FEED_LATER_USER_CONTENT_QUERY }   from "requests/graphql/queries/userLaterContent";
import { IframeType }                      from "requests/graphql/enums/iframeType";
import "./PendingPrograms.scss";
import "./PendingPrograms.media-850.scss";
import { useTranslation } from 'react-i18next';

const PendingPrograms: React.FC = () => {
  // TODO : manage error
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, loading, error } = useQuery(FEED_LATER_USER_CONTENT_QUERY, {
    fetchPolicy: "no-cache",
  });
  const { t, i18n } = useTranslation();


  function _getCurrentDate(): string {
    let now: Date     = new Date(),
        day: string   = "" + now.getDate(),
        month: string = "" + ( now.getMonth() + 1 ),
        year: number  = now.getFullYear();

    if (day.length < 2)
      day = "0" + day;

    if (month.length < 2)
      month = "0" + month;

    return [day, month, year].join("/");
  }


  /**
   * According to the shitty GraphQL server, we need to parse the information received fro our application
   * @param id
   * @param title
   * @param tcContent
   * @param index
   */
  function _buildProgram({ id, title, tcContent }: any, index: number): JSX.Element {

    let program: PendingProgram = {
      id,
      title,
      content: {
        id:          tcContent.id,
        duration:    tcContent.length,
        title:       tcContent.title1,
        shortPhrase: tcContent.catchy_phrase,
        type:        ( tcContent.iframe_audio && IframeType.Audio ) || ( tcContent.iframe_media && IframeType.Media ) || ( tcContent.iframe_text && IframeType.Text ) || ( tcContent.iframe_video && IframeType.Video ),
      },
    };
    switch(program.content.type){
      case IframeType.Audio:
        program.content.previewLink = tcContent.iframe_audio;
        break;
      case IframeType.Media:
        program.content.previewLink = tcContent.iframe_media;
        break;
      case IframeType.Text:
        program.content.previewLink = tcContent.iframe_text;
        break;
      case IframeType.Video:
        program.content.previewLink = tcContent.iframe_video;
        break;
    }
    return <PendingProgramWrapper key={index} className={"pending-programs__pending-program"} program={program}/>;
  }


  return (
    <div className={"pending-programs"}>
      <small className={"pending-programs__date"}>
        <DateRangeIcon/>
        <span className="pending-programs__date-text">
          {_getCurrentDate()}
        </span>
      </small>
      <h2 className="pending-programs__title">{t('views.pendingPrograms.title')}</h2>
      <div className={"pending-programs__programs"}>
        {
          loading
          ? <span className={"pending-programs__loading-message"}>{t('loading')}</span>
          : Array.isArray(data?.userLaterContents) && !!data?.userLaterContents?.length
            ? data?.userLaterContents.map(_buildProgram)
            : <span className={"pending-programs__no-pending-programs-message"}>{t('views.pendingPrograms.noProgramPending')}</span>
        }
      </div>
    </div>
  );
};

export default PendingPrograms;

/**
 * The trick to viewport units on mobile
 * (cf. https://css-tricks.com/the-trick-to-viewport-units-on-mobile/)
 */
export function defineBrowserViewHeight(): void {
  console.debug("defineBrowserViewHeight");
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--browser-view-height', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--browser-view-height', `${vh}px`);
  });

  window.addEventListener("orientationchange", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--browser-view-height', `${vh}px`);
  });
}
